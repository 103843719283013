export const MAKE_CONSULTATION = "MAKE_CONSULTATION";
export const MAKE_CONSULTATION_REQUEST = "MAKE_CONSULTATION_REQUEST";
export const MAKE_CONSULTATION_FAIL = "MAKE_CONSULTATION_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";

export const BOOKING_REQUEST = "BOOKING_REQUEST";
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const BOOKING_FAIL = "BOOKING_FAIL";

export const FLORAECOPOLIS_REQUEST = "FLORAECOPOLIS_REQUEST";
export const FLORAECOPOLIS_SUCCESS = "FLORAECOPOLIS_SUCCESS";
export const FLORAECOPOLIS_FAIL = "FLORAECOPOLIS_FAIL";
